<template>
  <div class="bills-content">
    <a-card class="mt-2">
      <div class="d-flex justify-content-between align-items-center mb-4">
      <span class="h5 mb-0"><font-awesome-icon icon="money-check"
                                               class="mt-1 mr-1 font-size-24"/> {{ $t('common.agent') }} {{ $t('common.balance') }} {{ $t('common.flows') }}</span>
      </div>
      <!-- First row -->
      <div class="d-flex mb-3">
        <!-- Select agent -->
        <div class="ml-0">
          <agent-dropdown v-model="accountId" @input="agentUpdated" class="width-150" />
        </div>
        <div class="ml-3">
          <a-select v-model="type" :placeholder="$t('placeholders.selectType')" class="width-150"
                    @change="typeUpdated">
            <a-select-option :value="0">
              [{{ $t('pre.all') }} {{ $t('common.types') }}]
            </a-select-option>
            <a-select-option :value="'deposit'">
              <a-icon type="arrow-down" class="text-success" /> {{ $t('common.income') }}
            </a-select-option>
            <a-select-option :value="'withdraw'">
              <a-icon type="arrow-up" class="text-danger" /> {{ $t('common.outcome') }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3">
          <a-select v-model="model" :placeholder="$t('placeholders.selectModel')" class="width-150"
                    @change="modelUpdated">
            <a-select-option :value="0">
              [{{ $t('pre.all') }} {{ $t('common.models') }}]
            </a-select-option>
            <a-select-option v-for="model in availableModels" :key="model.key">
              <a-icon v-if="model.type === 'deposit'" type="arrow-down" class="text-success" />
              <a-icon v-if="model.type === 'withdraw'" type="arrow-up" class="text-danger" />
              {{ model.value }}
            </a-select-option>
          </a-select>
        </div>
        <div class="ml-3" v-if="showRewardSubModel">
          <a-select v-model="rewardSubModel" :placeholder="`${$t('common.reward')} ${$t('buttons.btnType')}`" class="width-150"
                    @change="rewardSubModelUpdated">
            <a-select-option :value="0">
              [{{ $t('pre.all') }} {{ $t('common.types') }}]
            </a-select-option>
            <a-select-option v-for="model in rewardTypes" :key="model.key">
              {{ model.value }}
            </a-select-option>
          </a-select>
        </div>
        <!-- Select agent -->
        <!-- Select date -->
        <div class="ml-3 mr-3">
          <a-button-group class="range-picker-with-btn" style="width: 360px">
            <a-range-picker
              v-model="datetime"
              format="YYYY-MM-DD"
              :allowClear="false"
              @change="filtersUpdated"
            />
            <a-button @click="toggleUseDatetime" :type="useDatetime ? 'primary' : ''">
              <a-icon type="search"/>
            </a-button>
          </a-button-group>
        </div>
        <!-- Select date -->
        <!-- Select search -->
        <div class="" style="margin-left: auto;">
          <a-input-search
            v-model="search"
            :placeholder="$t('placeholders.selectSearch')"
            style="min-width: 100px; width: 100%;"
            allowClear
            @search="updateTable"
          >
          </a-input-search>
        </div>
        <!-- Select search -->
      </div>
      <!-- First row -->
      <!-- Second row -->
      <div class="mt-2 mb-3">
        <a-row :gutter="24" type="flex" align="top">
          <a-col :span="8">
            <a-statistic :title="`${$t('common.total')} ${$t('common.balance')} ${$t('common.transactions')}`" :value="statData.counters.all"
                         style="margin-right: 50px">
            </a-statistic>
            <div class="d-flex" v-if="type === 0 && model === 0">
              <div>
                <span><a-icon type="arrow-down" class="text-success" /> Income: {{ statData.counters.deposit.all }}</span>
              </div>
              <div class="ml-2">
                <span><a-icon type="arrow-up" class="text-danger" /> Outcome: {{ statData.counters.withdraw.all }}</span>
              </div>
            </div>
            <div v-if="type === 'deposit' && model === 0" class="d-flex">
              <div>
                <span>Sell: <span class="font-weight-bolder">{{ statData.counters.deposit.sale }}</span></span>
              </div>
              <div class="ml-2">
                <span>Rewards: <span class="font-weight-bolder">{{ statData.counters.deposit.reward }}</span></span>
              </div>
              <div class="ml-2">
                <span>Admin: <span class="font-weight-bolder">{{ statData.counters.deposit.deposit }}</span></span>
              </div>
            </div>
            <div v-if="type === 'withdraw' && model === 0" class="d-flex">
              <div>
                <span>Payouts: <span class="font-weight-bolder">{{ statData.counters.withdraw.payout }}</span></span>
              </div>
              <div class="ml-2">
                <span>Admin: <span class="font-weight-bolder">{{ statData.counters.withdraw.accrual }}</span></span>
              </div>
            </div>
            <div v-if="model === 'reward'" class="d-flex">
              <div>
                <span>Buy: <span class="font-weight-bolder">{{ statData.counters.deposit.reward_buy }}</span></span>
              </div>
              <div class="ml-2">
                <span>Sell: <span class="font-weight-bolder">{{ statData.counters.deposit.reward_sell }}</span></span>
              </div>
            </div>
            <div></div>
          </a-col>
          <a-col :span="8" v-if="(type === 0 && model === 0) || (type === 0 && getModel(model) && getModel(model).type === 'deposit') || type === 'deposit'">
            <a-statistic :value="statData.sums.deposit.all ? statData.sums.deposit.all : 0"
                         prefix="" style="margin-right: 50px">
              <template #title><a-icon type="arrow-down" class="text-success" /> {{ `${$t('common.income')} ${$t('common.sum')} USD` }}</template>
            </a-statistic>
            <div v-if="(type === 'deposit' && model === 0) || (type === 0 && model === 0)" class="d-flex">
              <div>
                <span> Sell: <span class="font-weight-bolder">{{ statData.sums.deposit.sale }}</span></span>
              </div>
              <div class="ml-2">
                <span> Rewards: <span class="font-weight-bolder">{{ statData.sums.deposit.reward }}</span></span>
              </div>
              <div class="ml-2">
                <span> Admin: <span class="font-weight-bolder">{{ statData.sums.deposit.deposit }}</span></span>
              </div>
            </div>
            <div v-if="model === 'reward'" class="d-flex">
              <div>
                <span> Buy: <span class="font-weight-bolder">{{ statData.sums.deposit.reward_buy }}</span></span>
              </div>
              <div class="ml-2">
                <span> Sell: <span class="font-weight-bolder">{{ statData.sums.deposit.reward_sell }}</span></span>
              </div>
            </div>
          </a-col>
          <a-col :span="8" v-if="(type === 0 && model === 0) || (type === 0 && getModel(model) && getModel(model).type !== 'deposit') || type === 'withdraw'">
            <a-statistic :value="statData.sums.withdraw.all ? statData.sums.withdraw.all : 0"
                         prefix="" style="margin-right: 50px">
              <template #title><a-icon type="arrow-up" class="text-danger" /> {{ `${$t('common.outcome')} ${$t('common.sum')} USD` }}</template>
            </a-statistic>
            <div v-if="(type === 'withdraw' && model === 0) || (type === 0 && model === 0)" class="d-flex">
              <div>
                <span> Payouts: <span class="font-weight-bolder">{{ statData.sums.withdraw.payout }}</span></span>
              </div>
              <div class="ml-2">
                <span> Admin: <span class="font-weight-bolder">{{ statData.sums.withdraw.accrual }}</span></span>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="accountId !== 0 && useDatetime" class="mt-3 p-1 mb-0 width-100p text-center small" style="background: #f0f2f4">
        <span class="text-center" style="cursor: pointer" @click="agentBalanceChart = !agentBalanceChart"><a-icon type="line-chart" />
          <span v-if="!agentBalanceChart">Show balance chart</span>
          <span v-else>Hide balance chart</span>
        </span>
      </div>
      <div v-if="accountId && agentBalanceChart && useDatetime" class="width-100p">
        <AgentBalanceChart
          :agent-id="accountId"
          :chart-id="`a-balance-chart-${accountId}`"
          :key="chartKey"
          :from-date="useDatetime ? datetime[0].format('YYYY-MM-DD HH:mm:ss') : ''"
          :to-date="useDatetime ? datetime[1].format('YYYY-MM-DD HH:mm:ss') : ''"
        />
      </div>
      <!-- Second row -->
      <a-table
        :columns="table.columns"
        :dataSource="table.rows"
        :row-key="record => record.id"
        :pagination="table.pagination"
        :loading="table.loading"
        @change="handleTableChange"
      >
        <template v-slot:id="id">
          <text-clipboard :text="id" />
        </template>
        <template v-slot:created_at="created_at">
          <time-stamp v-if="created_at" :date="created_at" />
          <span v-else>-</span>
        </template>
        <template v-slot:agent="agent">
              <span class="mr-1">
                <img v-if="agent.sex === 'male'" src="/resources/images/avatars/user-agent-m.png" width="18"/>
                <img v-else src="/resources/images/avatars/user-agent-w.png" width="18"/>
              </span>
          <span v-if="agent">{{ agent.name }}</span>
        </template>
        <template v-slot:amount="amount, record">
          <a-icon v-if="record.type === 'deposit'" type="arrow-down" class="text-success" />
          <a-icon v-else type="arrow-up" class="text-danger" />
          $ {{ Math.abs(amount) }}
        </template>
        <template v-slot:balance="balance, record">
          <span v-if="record.prev_balance" class="small text-gray-4"><s>$ {{ record.prev_balance }}</s></span>
          <span class="d-block">$ {{ balance }}</span>
        </template>
        <template v-slot:model="model, record">
          <span>{{ getModel(model).value }}</span>
          <span v-if="record.model === 'reward' && record.sub_m" class="small text-gray-5">
            <span v-if="record.sub_m === 'client_buy'"> [Buy]</span>
            <span v-if="record.sub_m === 'client_sell'"> [Sell]</span>
          </span>
        </template>
        <template v-slot:model_id="modelId, record">
          <text-clipboard
            v-if="record.model === 'reward' && record.sub_m_id"
            :text="record.sub_m_id"
          />
          <text-clipboard
            v-else
            :text="modelId"
          />
        </template>
        <template v-slot:status="status">
          <template v-if="status === 'CREATED'">
            <a-tooltip title="Created" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
            </a-tooltip>
          </template>
          <template v-else-if="status === 'PENDING'">
            <a-tooltip title="Created" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
            </a-tooltip>
          </template>
          <template v-else-if="status === 'PROCESSING'">
            <a-tooltip title="Processing" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
            </a-tooltip>
          </template>
          <template v-else-if="status === 'PAID'">
            <a-tooltip title="Paid" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-success"/>
              </span>
            </a-tooltip>
          </template>
          <template v-else-if="status === 'EXPIRED'">
            <a-tooltip title="Expired" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="money-check" class="text-gray-4"/>
              </span>
            </a-tooltip>
          </template>
          <template v-else-if="status === 'CANCELED'">
            <a-tooltip title="Canceled" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="times-circle" class="text-danger"/>
              </span>
            </a-tooltip>
          </template>
          <template v-else-if="status === 'EXTENDED'">
            <a-tooltip title="Extended" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="times-circle" class="text-danger"/>
              </span>
            </a-tooltip>
          </template>
          <template v-else-if="status === 'FAILED'">
            <a-tooltip title="Failed" placement="bottom">
              <span class="font-size-18">
                <font-awesome-icon icon="times-circle" class="text-danger"/>
              </span>
            </a-tooltip>
          </template>
        </template>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { reactive } from 'vue'
import apiClient from '@/services/axios'
import AgentBalanceChart from '@/components/charts/AgentBalanceChart.vue'
import AccountsBalanceOperationsService
from '../../../services/api/accounts/balance-operations/accounts.balance.operations.service'
import AgentDropdown from '../../../components/agent/AgentDropdown.vue'
import TimeStamp from '../../../components/table/atomic/TimeStamp.vue'
import TextClipboard from '../../../components/table/atomic/TextClipboard.vue'

export default {
  name: 'agentBalanceOperations',
  components: { TextClipboard, TimeStamp, AgentDropdown, AgentBalanceChart },
  computed: {
    availableModels() {
      if (this.type !== 0) {
        return this.models.filter((model) => model.type === this.type)
      } else {
        return this.models
      }
    },
    showRewardSubModel() {
      return this.model === 'reward'
    },
  },
  data() {
    return {
      useDatetime: true,
      exceptDevs: false,
      agentBalanceChart: false,
      type: 0,
      model: 0,
      // showRewardSubModel: false,
      rewardSubModel: 0,
      models: [
        {
          key: 'client_sale',
          value: 'Sell',
          type: 'deposit',
        },
        {
          key: 'reward',
          value: 'Reward',
          type: 'deposit',
        },
        {
          key: 'deposit',
          value: 'Admin deposit',
          type: 'deposit',
        },
        {
          key: 'payout',
          value: 'Payout',
          type: 'withdraw',
        },
        {
          key: 'accrual',
          value: 'Admin withdraw',
          type: 'withdraw',
        },
      ],
      rewardTypes: [
        {
          key: 'client_buy',
          value: 'Buy',
        },
        {
          key: 'client_sell',
          value: 'Sell',
        },
      ],
      statData: {
        counters: {
          all: 0,
          deposit: { all: 0, deposit: 0, reward: 0, sale: 0 },
          withdraw: { all: 0, accrual: 0, payout: 0 },
        },
        sums: {
          deposit: { all: 0, deposit: 0, reward: 0, sale: 0 },
          withdraw: { all: 0, accrual: 0, payout: 0 },
        },
      },
      accountsData: [],
      accountId: 0,
      search: '',
      table: reactive({
        rows: [],
        columns: [
          {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            scopedSlots: { customRender: 'id' },
          },
          {
            title: 'Created',
            dataIndex: 'created_at',
            key: 'created_at',
            scopedSlots: { customRender: 'created_at' },
          },
          {
            title: 'Agent',
            dataIndex: 'agent',
            key: 'agent',
            scopedSlots: { customRender: 'agent' },
          },
          {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            scopedSlots: { customRender: 'amount' },
          },
          {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            scopedSlots: { customRender: 'balance' },
          },
          {
            title: 'Model',
            dataIndex: 'model',
            key: 'model',
            scopedSlots: { customRender: 'model' },
          },
          {
            title: 'Model ID',
            dataIndex: 'model_id',
            key: 'model_id',
            scopedSlots: { customRender: 'model_id' },
          },
          // {
          //   title: 'Status',
          //   dataIndex: 'status',
          //   key: 'status',
          //   scopedSlots: { customRender: 'status' },
          // },
        ],
        loading: false,
        pagination: {
          current: 1,
          pageSize: 10,
          total: -1,
        },
      }),
      datetime: [
        this.$moment(this.$moment().utc().startOf('month'), 'YYYY-MM-DD'),
        this.$moment(this.$moment().utc(), 'YYYY-MM-DD').endOf('day'),
      ],
      chartKey: 'default',
    }
  },
  async mounted() {
    await this.getAccounts()
    await this.fetchRecords()
    await this.getStatData()
  },
  methods: {
    getModel(modelKey) {
      return this.models.find(i => i.key === modelKey)
    },
    toggleUseDatetime() {
      this.useDatetime = !this.useDatetime
      this.filtersUpdated()
    },
    async getStatData() {
      try {
        // const url = '/admin/accounts/payouts/analytics/digits'

        const payload = {
          agent_id: this.accountId,
          search: this.searchValue,
          type: this.type,
          model: this.model,
          sub_model: this.rewardSubModel,
        }

        payload.from_date = this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        payload.to_date = this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss')

        if (this.exceptDevs) { payload.except_devs = true }

        const response = await AccountsBalanceOperationsService.getAccountBalanceOperationsDigits(payload)
        this.statData = response.data.data
      } catch (e) {
        console.log(e)
      }
    },
    async typeUpdated() {
      if (this.type === 0) { this.model = 0 }
      if (this.type !== 0 && this.model !== 0 && this.models.find(i => i.key === this.model).type !== this.type) {
        this.model = 0
      }
      await this.filtersUpdated()
    },
    async modelUpdated() {
      // if (this.model === 'reward') {
      //   this.showRewardSubModel = true
      // }
      // this.showRewardSubModel = this.model === 'reward'
      if (this.model !== 'reward') {
        this.rewardSubModel = 0
      }
      await this.filtersUpdated()
    },
    async rewardSubModelUpdated() {
      await this.filtersUpdated()
    },
    async agentUpdated() {
      // if (this.accountId !== 0) {
      //   this.chartKey = 'agent-balance-chart-' + this.accountId
      // }
      await this.filtersUpdated()
    },
    async filtersUpdated() {
      this.table.pagination.current = 1
      await this.getStatData()
      await this.fetchRecords()

      if (!this.useDatetime) { this.agentBalanceChart = false }

      if (this.agentBalanceChart === true && this.accountId !== 0) {
        this.chartKey = 'agent-balance-chart-' + this.accountId + this.datetime[0].format('YYYY-MM-DD HH:mm:ss') + this.datetime[1].format('YYYY-MM-DD HH:mm:ss')
      }
    },
    handleTableChange(pagination) {
      this.table.pagination.current = pagination.current
      this.fetchRecords()
    },
    async getAccounts() {
      try {
        const url = '/admin/accounts?short'
        const response = await apiClient.get(url)
        this.accountsData = response.data.data
      } catch (e) {
        console.log(e)
      } finally {
      }
    },
    async fetchRecords() {
      try {
        this.table.loading = true

        const payload = {
          page: this.table.pagination.current,
          results: this.table.pagination.pageSize,
          agent_id: this.accountId,
          search: this.search,
          type: this.type,
          model: this.model,
          sub_model: this.rewardSubModel,
        }

        payload.from_date = this.$moment(this.datetime[0]).format('YYYY-MM-DD HH:mm:ss')
        payload.to_date = this.$moment(this.datetime[1]).format('YYYY-MM-DD HH:mm:ss')

        const response = await AccountsBalanceOperationsService.getAccountBalanceOperations(payload)
        this.table.rows = response.data.data
        this.table.pagination.total = response.data.meta.total
      } catch (e) {
        console.log(e)
      } finally {
        this.table.loading = false
      }
    },
    async updateTable() {
      this.table.pagination.current = 1
      await this.fetchRecords()
      await this.getStatData()
    },
  },
}
</script>
<style>
</style>

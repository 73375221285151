<template>
  <div class="container text-center">
    <Bar v-if="loaded"
         :chart-id="chartId"
         :chart-data="chartData"
         :chart-options="chartOptions"
         :width="width"
    />
    <span v-else><a-icon type="loading" /></span>
  </div>
</template>
<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import AccountsBalanceOperationsService
from '../../services/api/accounts/balance-operations/accounts.balance.operations.service'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'AgentBalanceChart',
  // props: ['agentId', 'fromDate', 'toDate'],
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    agentId: {
      type: Number,
      default: 1,
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    fromDate: {
      type: String,
      default: '',
    },
    toDate: {
      type: String,
      default: '',
    },
    bottomLabels: {
      type: Boolean,
      default: false,
    },
    withAnimation: {
      type: Boolean,
      default: false,
    },
  },
  components: { Bar },
  data: () => ({
    loaded: false,
    chartData: null,
    chartOptions: {
      // animation: this.withAnimation,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: {
          ticks: {
            display: false,
          },
          grid: {
            display: false,
          },
        },
        y: {
          grid: {
            display: false,
          },
        },
      },
    },
  }),
  async mounted () {
    this.loaded = false

    try {
      const useDates = this.fromDate && this.toDate
      console.log(useDates, 'use dates')
      const params = {
        agent_id: this.agentId,
      }
      if (useDates) {
        params.from_date = this.fromDate
        params.to_date = this.toDate
      }
      const chartData = await AccountsBalanceOperationsService.getAccountBalanceFlowDigits(params)
      const labels = chartData.labels
      const digits = chartData.data
      console.log(chartData, 'chartData')
      // const testData = [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
      this.chartData = {
        datasets: [{ data: digits, label: 'Balance', backgroundColor: '#f87979' }],
        labels: labels,
      }

      this.loaded = true
    } catch (e) {
      console.error(e)
    }
  },
}
</script>

<style scoped lang="scss">

</style>
